import axios from 'axios';

const auth = {
  generateToken: (tokenType, token) => {
    localStorage.setItem('onlyToken', token);

    return `${tokenType} ${token}`;
  },

  setToken: (token) => {
    localStorage.setItem('token', token);

    axios.defaults.headers.common = {
      Authorization: token,
    };
  },

  getToken: () => localStorage.getItem('token'),

  getOnlyToken: () => localStorage.getItem('onlyToken'),

  check: () => {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }
    return false;
  },

  removeToken: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE;
    axios.defaults.headers.common = {
      Authorization: null,
    };
  },

  saveUser: (user) => localStorage.setItem('user', JSON.stringify(user)),

  user: () => {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  },
};

export default auth;
