<template>
  <el-dialog
    title="Videos disponibles para esta sección"
    :visible="showModalELearning"
    width="800px"
    :before-close="onClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-collapse v-model="activeName" accordion class="collapse-videos">
      <el-collapse-item
        v-for="(video, i) in classRoomVideos"
        :key="i"
        :title="video.title"
        :name="i"
      >
        <p>
          {{ video.summary }}
        </p>
        <template v-if="video.type === 'Vimeo'">
          <LazyVimeo :src="video.video" />
        </template>
        <template v-if="video.type === 'Youtube'">
          <LazyYoutube :src="video.video" />
        </template>
        <template v-if="video.type === 'Otros'">
          <div class="video__wrapper" v-html="video.video"></div>
        </template>
      </el-collapse-item>
    </el-collapse>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { LazyVimeo, LazyYoutube } from 'vue-lazytube';

export default {
  name: 'ModalVideoELearning',
  components: {
    LazyVimeo,
    LazyYoutube,
  },
  data: () => ({
    activeName: '',
  }),
  computed: {
    ...mapGetters(['classRoomVideos', 'showModalELearning']),
  },
  methods: {
    onClose() {
      this.$store.dispatch('ON_SET_SHOWMODALELEARNING', false);
    },
  },
};
</script>
