<!-- eslint-disable -->
<template>
  <aside class="wrapper__aside" :class="{ 'is-collapse': isCollapse }">
    <div class="wrapper__aside-topbar">
      <img src="@/assets/images/intranort-logo.png" alt="Logo intranort" class="img-fluid logo" />
      <el-button type="text" @click="onHideShowSidebar()" class="btn-collapse">
        <template v-if="isCollapse">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-circle">
            <circle cx="12" cy="12" r="10"></circle>
          </svg>
        </template>
        <template v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-disc">
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </template>
      </el-button>
      <el-button type="text" class="btn-close-menu" @click="onCloseMenu()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-x">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </el-button>
    </div>
    <el-scrollbar
      :style="{ height: menuHeight, overflowX: 'hidden' }"
      class="hide-x"
    >
      <el-menu :collapse="isCollapse" :router="true">
        <template v-for="(module, i) in user.menu">
          <el-submenu :key="i" v-if="module.has_children" :index="i.toString()">
            <template slot="title">
              <i :class="module.icon"></i>
              <span>{{ module.description }}</span>
            </template>
            <el-menu-item-group class="no-title" v-if="module.has_children">
              <el-menu-item v-for="(page, j) in module.sections" :key="j" :index="`/dashboard/${page.path}`">{{
              page.description }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-else :index="`/${module.path}`" :key="i">
            <i :class="module.icon"></i>
            <span>{{ module.description }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarComponent',
  data: () => ({
    menuHeight: '1px',
  }),
  mounted() {
    this.menuHeight = 'calc(100vh - 62px)';
  },
  computed: {
    ...mapGetters(['isCollapse', 'user']),
  },
  methods: {
    onHideShowSidebar() {
      this.$store.commit('ON_HIDE_SHOW_SIDEBAR');
    },
    onCloseMenu() {
      this.$store.commit('ON_CLOSE_MENU');
    },
  },
};
</script>
